import { TokenList } from '@uniswap/token-lists'

/**
 * Contains the logic for resolving a list URL to a validated token list
 * @param url list url
 * @param resolveENSContentHash resolves an ens name to a contenthash
 */
export default async function getTokenList(
  url: string,
  resolveENSContentHash: (ensName: string) => Promise<string>
): Promise<TokenList> {
  let response
  try {
    response = await fetch(url)
  } catch (error) {
    console.debug('Failed to fetch list', url, error)
    throw new Error(`Failed to download list ${url}`)
  }

  if (!response.ok) {
    throw new Error(`Failed to download list ${url}`)
  }

  return await response.json()
}
